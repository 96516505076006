'use strict';

import _ from 'shared/js/underscore';

const util = require('improove/util/util');
var previousScreenSize = util.getScreenSize();

function setCookie(key, value, expiresInDays) {
    var date = new Date();
    date.setTime(date.getTime() + (expiresInDays * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';path=/;expires=' + date.toUTCString();
}

function deleteCookie(name) {
    document.cookie = name + '=1;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

// For the removal of the cookies that have different domain
function eraseCookie(name) {
    var domain = window.location.hostname;
    domain = domain.substring(domain.indexOf('.'));
    document.cookie = name + '=1;domain=' + domain + ';path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function injectGTMScript() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var firstScript = document.getElementsByTagName('script')[0];
    var scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = '//' + window.GTM_URL + '/gtm.js?id=' + window.GTM_CONTAINER_ID;
    firstScript.parentNode.insertBefore(scriptElement, firstScript);
}

function setInitialValues() {
    // Cookie Consent Settings Init
    var functionalityStorage = 'denied';
    var analyticsStorage = 'denied';
    var adStorage = 'denied';
    var adUserData = 'denied';
    var adPersonalization = 'denied';

    if ($('.cookie-message-banner').length === 0 || $('.cookie-message-banner').hasClass('hide')) {
        if (document.cookie.indexOf('set_functional_cookies=1') > 0) {
            functionalityStorage = 'granted';
        }

        if (document.cookie.indexOf('set_analytics_cookies=1') > 0) {
            analyticsStorage = 'granted';
        }

        if (document.cookie.indexOf('set_marketing_cookies=1') > 0) {
            adStorage = 'granted';
            adPersonalization = 'granted';
            adUserData = 'granted';
        }

        window.gtag('consent', 'update', {
            ad_storage: adStorage,
            ad_personalization: adPersonalization,
            ad_user_data: adUserData,
            analytics_storage: analyticsStorage,
            functionality_storage: functionalityStorage,
            // Docs suggest to have it at least 500 ms, increasing it to 1000 ms for the case of 1st time site visit.
            wait_for_update: 1000
        });
    }

    // Inject GTM script after defining the default and inital updated consent values
    if (window.GTM_ENABLED && window.GTM_CONTAINER_ID) {
        injectGTMScript();
    }
}

const execute = function () {
    $(window).on(
        'load resize',
        _.debounce(function () {
            var currentScreenSize = util.getScreenSize();
            if (previousScreenSize != currentScreenSize) {
                previousScreenSize = currentScreenSize;
            }
        }));

    /* BIG COOKIE BANNER */
    // #CDIFF PF <> FM <> GG - has 2d part of condition, is used PF version
    if (document.cookie.indexOf('set_necessary_cookies=1') < 0 && !$('.cookie-message-banner').hasClass('old-cookie-banner')) {
        $('.cookie-message-banner').removeClass('hide');
        $('.cookie-banner-overlay').removeClass('hide');
    }

    $('[data-view-cookie-details]').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $('.cookie-message-banner').addClass('hide');
        $('.cookie-details-wrapper').removeClass('hide');
    });

    $('[data-open-cookie-policy]').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $('.cookie-details-wrapper').addClass('hide');
        $('.cookie-message-banner').addClass('hide');
        $('.cookie-policy-wrapper').removeClass('hide');
    });

    $('[data-open-integritets-policy]').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $('.cookie-details-wrapper').addClass('hide');
        $('.cookie-message-banner').addClass('hide');
        $('.cookie-integrity-wrapper').removeClass('hide');
    });

    $('[data-return-to-banner]').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $('.cookie-message-banner').removeClass('hide');
        $('.cookie-details-wrapper').addClass('hide');
        $('.cookie-policy-wrapper').addClass('hide');
        $('.cookie-integrity-wrapper').addClass('hide');
    });

    $('.cookie-message-banner .save-cookie-button ').on('click', function () {
        setCookie('set_necessary_cookies', 1, 365);
        setCookie('set_functional_cookies', 1, 365);
        setCookie('set_analytics_cookies', 1, 365);
        setCookie('set_marketing_cookies', 1, 365);
        $('.cookie-message-banner').addClass('hide');
        $('.cookie-banner-overlay').addClass('hide');
    });

    $('[data-save-all-cookies]').on('click', function () {
        $('.cookie-checkbox').each(function () {
            if ($(this).is(':checked') !== true) {
                $(this).prop('checked', true);
            }
        });
        setCookie('set_necessary_cookies', 1, 365);
        setCookie('set_functional_cookies', 1, 365);
        setCookie('set_analytics_cookies', 1, 365);
        setCookie('set_marketing_cookies', 1, 365);
        $('.cookie-details-wrapper').addClass('hide');
        $('.cookie-banner-overlay').addClass('hide');
    });

    $('[data-deny-all-cookies], .cookie-message-banner .save-necessary-cookie-button').on('click', function () {
        $('.cookie-checkbox').each(function () {
            if ($(this).is(':checked') && $(this).is(':disabled') !== true) {
                $(this).prop('checked', false);
            }
        });
        setCookie('set_necessary_cookies', 1, 365);
        $('.cookie-message-banner').addClass('hide');
        $('.cookie-details-wrapper').addClass('hide');
        $('.cookie-banner-overlay').addClass('hide');
    });

    $('[data-save-cookies]').on('click', function () {
        setCookie('set_necessary_cookies', 1, 365);

        if ($('#cookie-checkbox-functional').is(':checked')) {
            setCookie('set_functional_cookies', 1, 365);
        }

        if ($('#cookie-checkbox-analytics').is(':checked')) {
            setCookie('set_analytics_cookies', 1, 365);
        }

        if ($('#cookie-checkbox-marketing').is(':checked')) {
            setCookie('set_marketing_cookies', 1, 365);
        }

        $('.cookie-details-wrapper').addClass('hide');
        $('.cookie-banner-overlay').addClass('hide');
    });

    $('[data-reset-cookies]').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        setCookie('set_marketing_cookies', 1, 365);

        if (document.cookie.indexOf('set_necessary_cookies=1') >= 0) {
            deleteCookie('set_necessary_cookies');
        }

        if (document.cookie.indexOf('set_functional_cookies=1') >= 0) {
            deleteCookie('set_functional_cookies');
        }

        if (document.cookie.indexOf('set_analytics_cookies=1') >= 0) {
            deleteCookie('set_analytics_cookies');
        }

        if (document.cookie.indexOf('set_marketing_cookies=1') >= 0) {
            deleteCookie('set_marketing_cookies');
        }

        if (document.cookie.indexOf('sg_optin=1') >= 0) {
            eraseCookie('sg_optin');
        }

        $('.cookie-message-banner').addClass('hide');
        $('.cookie-details-wrapper').removeClass('hide');
    });

    if (document.cookie.indexOf('set_marketing_cookies=1') > 0) {
        $('#cookie-checkbox-marketing').prop('checked', true);
    }
    if (document.cookie.indexOf('set_functional_cookies=1') > 0) {
        $('#cookie-checkbox-functional').prop('checked', true);
    }
    if (document.cookie.indexOf('set_analytics_cookies=1') > 0) {
        $('#cookie-checkbox-analytics').prop('checked', true);
    }

    // Moved from the initial version of gtmConsent.isml
    $(document).ready(function () {
        setInitialValues();

        // Update Consent Settings On Cookie Settings Clicks
        $('.cookie-message-banner .save-cookie-button, [data-save-all-cookies]').on('click', function () {
            window.gtag('consent', 'update', {
                ad_storage: 'granted',
                ad_personalization: 'granted',
                ad_user_data: 'granted',
                analytics_storage: 'granted',
                functionality_storage: 'granted',
            });

            document.dispatchEvent(
                new CustomEvent('GTM-push-consent-update',
                    {
                        detail: {
                            event: 'cookie_consent_update'
                        }
                    })
            );
        });

        $('[data-deny-all-cookies], .cookie-message-banner .save-necessary-cookie-button').on('click', function () {
            window.gtag('consent', 'update', {
                ad_storage: 'denied',
                ad_personalization: 'denied',
                ad_user_data: 'denied',
                analytics_storage: 'denied',
                functionality_storage: 'denied',
            });

            document.dispatchEvent(
                new CustomEvent('GTM-push-consent-update',
                    {
                        detail: {
                            event: 'cookie_consent_update'
                        }
                    })
            );
        });

        $('[data-save-cookies]').on('click', function () {
            var analytics_storage = 'denied';
            var ad_storage = 'denied';
            var ad_personalization = 'denied';
            var ad_user_data = 'denied';
            var functionality_storage = 'denied';

            if ($('#cookie-checkbox-analytics').is(':checked')) {
                analytics_storage = 'granted';
            }

            if ($('#cookie-checkbox-marketing').is(':checked')) {
                ad_storage = 'granted';
                ad_personalization = 'granted';
                ad_user_data = 'granted';
            }

            if ($('#cookie-checkbox-functional').is(':checked')) {
                functionality_storage = 'granted';
            }

            window.gtag('consent', 'update', {
                ad_storage: ad_storage,
                ad_personalization: ad_personalization,
                ad_user_data: ad_user_data,
                analytics_storage: analytics_storage,
                functionality_storage: functionality_storage
            });

            document.dispatchEvent(
                new CustomEvent('GTM-push-consent-update',
                    {
                        detail: {
                            event: 'cookie_consent_update'
                        }
                    })
            );
        });

        $('.cookie-message-banner button, .cookie-details-wrapper button').on('click', function () {
            $(document).trigger('cookie:update');
        });
    });
};

module.exports = (context) => {
    if (context === 'checkout') {
        setInitialValues();
    } else if (window.__pagesSyncInclude.indexOf('cookie-message-banner') > -1) {
        execute();
    } else {
        document.addEventListener('alpine:customer-sections:loaded', function (event) {
            // Await for cookie message banner being resolved through the customer sections.
            const cs = event.detail.cs;
            cs.getAwait('assets', 'page.cookie-message-banner').then(() => {
                execute();
            });
        });
    }
};
